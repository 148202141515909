/* Footer.css */

.footer {
    background-color: #333;
    color: white;
    padding: 20px;
  }
  
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .address, .email, .directors {
    margin-bottom: 20px;
  }
  
  h4 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  
  p {
    margin: 0;
    font-size: 14px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin-bottom: 10px;
    font-size: 14px;
  }
  