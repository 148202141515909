.image-carousel {
    width: 100%;
    margin-top: 50px;
  }
  
  .slide {
    position: relative;
    text-align: center;
  }
  
  .slide img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .slide .description {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    width: 80%;
    max-width: 300px;
    text-align: center;
    border-radius: 5px;
    transition: opacity 0.3s ease;
  }
  
  .description:hover {
    opacity: 0.8;
  }
  
  .description:before {
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-top: 5px solid rgba(0, 0, 0, 0.7);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  
  .description:after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 5px solid rgba(0, 0, 0, 0.7);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  