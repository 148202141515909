/* CSS Styling */
body {
    position: relative;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 700px;
    font-weight: bold;
    position: relative;
    z-index: 2;
    margin-bottom: 100px;
  }
  
  .about::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/laboratory.png'); /* Replace 'path_to_your_image.jpg' with the actual path to your image */
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(10px); /* Adjust the blur radius as desired */
    z-index: -1;
  }
  
  .header {
    color: white;
    padding: 20px;
  }
  
  h1 {
    font-size: 28px;
    margin-top: 0;
  }
  
  p {
    margin-bottom: 20px;
  }
  
  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .column {
    width: 100%;
  }
  
  /* Additional styling for the content */
  .column h2 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .column p {
    font-size: 16px;
  }
  