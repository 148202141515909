.our-clients {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  
  .our-clients h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .client-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
  }
  
  .client-item {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
  }
  
  .client-logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .client-name {
    font-size: 16px;
  }
  