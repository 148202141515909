/* Products.css */

.products {
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 20px;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.product {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
}

.product img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.product h2 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.product-details {
  margin-top: 20px;
}

.product-quality ul,
.product-application ul {
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style-type: disc;
  margin-left: 20px;
}

.product-quality li,
.product-application li {
  margin-bottom: 8px;
  line-height: 1.2;
  text-align: left; /* Align content to the left */
}

.product-quality li::marker,
.product-application li::marker {
  content: "•";
  color: #2075c7;
  font-weight: bold;
  margin-right: 5px;
}

