/* CSS Styling */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .navbar {
    background-color: #333;
    color: #fff;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 20px;
  }
  
  .navbar-icon {
      margin-right: 10px;
    }
    
    .navbar-icon img{
      height: 40px;

  }
  
  .navbar-title {
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .navbar-menu {
    list-style: none;
    margin: 0;
  }
  
  .navbar-menu li {
    display: inline-block;
    margin-right: 10px;
  }
  
  .navbar-menu li:last-child {
    margin-right: 0;
  }
  
  .navbar-menu li a {
    color: #fff;
    text-decoration: none;
  }
  
  .navbar-menu li a:hover {
    text-decoration: underline;
  }
  